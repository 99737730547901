import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    error: '',
  },
  getters: {
    isDark: () => window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches,
  },
});
