<template>
  <div id="app">
    <router-view v-if="$route.meta.layout === 'default'" />
    <nav-layout v-if="$route.meta.layout === 'nav'">
      <router-view />
    </nav-layout>
  </div>
</template>

<script>
import NavLayout from '@/layouts/Nav.vue';

export default {
  name: 'App',
  components: {
    NavLayout,
  },
  watch: {
    $route() {
      this.$amplitude.track('Page View', {
        page: this.$route.name,
      });
    },
  },
};
</script>

<!-- eslint-disable-next-line vue-scoped-css/require-scoped -->
<style lang="scss">
@import "~@/assets/css/normalize.css";
@import "~@/assets/css/skeleton.css";

/**********
 * Global MFD Styles
 **********
 */

/* Mobile first queries */
#app {
  margin: 0;
}

.section-with-margin {
  margin-left: 2rem;
  margin-right: 2rem;
}

/* Larger than mobile */
@media (min-width: 400px) {
  //
}

/* Larger than phablet */
@media (min-width: 550px) {
  //
}

/* Larger than tablet */
@media (min-width: 750px) {
  #app {
    margin: 0 auto;
    max-width: 750px;
  }

  .section-with-margin {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

/* Larger than desktop */
@media (min-width: 1000px) {
  //
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
  //
}

:root {
  --background-color: #fff;
  --background-accent-color: #eee;

  --primary-color: #2c3e50;
  --primary-accent-color: #3c4f61;

  --background-color-static: #fff;
  --background-accent-color-static: #eee;

  --primary-color-static: #2c3e50;
  --primary-accent-color-static: #3c4f61;

  --faded-background: rgba(44, 62, 80, 0.65);
  --box-shadow: rgba(44, 62, 80, 0.35);

  --pink: #d186c4;

  @media (prefers-color-scheme: dark) {
    --background-color: #2c3e50;
    --background-accent-color: #3c4f61;

    --primary-color: #fff;
    --primary-accent-color: #eee;

    --faded-background: rgba(71, 100, 129, 0.65);
    --box-shadow: rgba(71, 100, 129, 0.35);
  }
}

body {
  background-color: var(--background-color);
  color: var(--primary-color);
  font-family: 'Montserrat', sans-serif;
}

/* Style Overrides */
input[type="email"],
input[type="text"],
textarea,
select {
  border-radius: 0 !important;
  background-color: var(--background-color-static);
  border-color: var(--background-accent-color-static);
  color: var(--primary-color-static);

  &:focus {
    border-color: var(--primary-color) !important;
  }
}

.button {
  border-radius: 0 !important;
  background: var(--background-color) !important;
  border-color: var(--primary-color) !important;
  color: var(--primary-color) !important;

  @media (hover: hover) {
    &:hover {
      background: var(--primary-color) !important;
      border-color: var(--primary-color) !important;
      color: var(--background-color) !important;
    }
  }

  &:disabled,
  &.disabled {
    opacity: 0.5 !important;
    cursor: default;
  }
}

.button.button-primary {
  border-radius: 0 !important;
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: var(--background-color) !important;

  @media (hover: hover) {
    &:hover {
      background: var(--primary-color) !important;
      border-color: var(--primary-color) !important;
      color: var(--background-color) !important;
      opacity: 0.75 !important;
    }
  }

  &:disabled,
  &.disabled {
    opacity: 0.5 !important;
    cursor: default;
  }
}

.link {
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: var(--primary-color);
    opacity: 0.75;
  }
}

.error {
  color: red;
}

/** Utilities */
.flex-switch {
  display: flex;
  flex-direction: column;

  @media (min-width: 550px) {
    flex-direction: row;
  }

  .right {
    flex: 1;
    order: 1;
    text-align: left;
    margin-bottom: 2rem;

    @media (min-width: 550px) {
      width: 100%;
      text-align: right;
      order: 3;
    }
  }

  .left {
    flex: 1;
    order: 2;
  }
}

.tooltip {
  position: relative;
  display: inline-block;

  /* Tooltip text */
  .tooltipText {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    left: 0;
    right: 0;
    top: 2.5rem;
    z-index: 1;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  &:hover {
    .tooltipText {
      visibility: visible;
    }
  }
}

/** Typography */
h2 {
  text-align: center;
  font-size: 3.5rem;
  margin-top: 4rem;
  margin-bottom: 6rem;
}

.title {
  margin: 5px 0;
  font-family: 'Raleway', sans-serif;
  font-size: 2.5rem;
}

.subtitle {
  margin: 5px 0 8px;
  font-family: 'Raleway', sans-serif;
  font-size: 1.5rem;
  opacity: 0.75;
}

.center-container {
  display: flex;
  justify-content: center;
}

.text-right {
  text-align: right;
}

/** UX */
.alternating-sides {
  display: flex;
  margin: 24px;
  flex-direction: column;

  /* Larger than mobile */
  @media (min-width: 550px) {
    margin: 24px 0;
    flex-direction: row;
    gap: 24px;

    &:nth-of-type(2n - 1) {
      .images {
        order: 0;
      }

      .text {
        order: 1;
      }
    }

    &:nth-of-type(2n) {
      .images {
        order: 1;
      }

      .text {
        order: 0;
        text-align: right;
      }
    }
  }

  .images {
    flex: 1;
  }

  .text {
    flex: 1;
  }
}
</style>
