const HOME = 'HOME';
const EVENTS = 'EVENTS';
const WEDDING_PARTY = 'WEDDING_PARTY';
const OUR_STORY = 'OUR_STORY';
const TRAVEL_INFO = 'TRAVEL_INFO';
const REGISTRY = 'REGISTRY';
const FAQ = 'FAQ';
const REHEARSAL_FAQ = 'REHEARSAL_FAQ';

module.exports = {
  HOME,
  EVENTS,
  WEDDING_PARTY,
  OUR_STORY,
  TRAVEL_INFO,
  REGISTRY,
  FAQ,
  REHEARSAL_FAQ,
  PRERENDER: [
    '/',
    '/events',
    '/wedding-party',
    '/our-story',
    '/travel-info',
    '/registry',
    '/faq',
    '/rehearsal-faq',
  ],
};
