<template>
  <header class="section-with-margin">
    <h1 class="header">
      Lindsey &amp; Alex
    </h1>
    <p class="subheader">
      8 <span class="pink">•</span> 13 <span class="pink">•</span> 2022
      <span class="wide">|</span>
      Seattle<span class="pink">,</span> WA
    </p>
  </header>
</template>

<script>
export default {
  name: 'PageHeader',
};
</script>

<style lang="scss" scoped>
  header {
    top: -2rem;
    padding-top: 4rem;
    background: var(--background-color);
    z-index: 9999;

    .header {
      margin-bottom: 0;
      font-family: 'Cinzel', serif;
      text-align: center;
    }

    .subheader {
      font-size: 2.5rem;
      opacity: 0.75;
      font-family: 'Cinzel', serif;
      text-align: center;
      padding-bottom: 2.5rem;
      margin-bottom: 0;
    }

    .pink {
      color: var(--pink);
      font-weight: bold;
    }

    .wide {
      margin: 0 10px;
    }
  }
</style>
