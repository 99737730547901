<template>
  <div>
    <div
      ref="cover"
      class="cover"
    >
      <progressive-background
        class="inner"
        placeholder="/images/cover.jpg"
        src="/images/cover.jpg"
        no-ratio
      />
    </div>
    <div ref="spacer" />
  </div>
</template>

<script>
export default {
  name: 'CoverHeader',
  mounted() {
    this.resize();
    window.addEventListener('resize', this.resize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resize);
  },
  methods: {
    resize() {
      const height = this.$refs.cover.clientHeight;
      this.$refs.spacer.style.height = `${height}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
  .cover {
    position: absolute;
    top: 0;
    bottom: 165px;
    left: 0;
    right: 0;
    overflow: hidden;
  }
</style>

<!-- eslint-disable-next-line vue-scoped-css/require-scoped -->
<style lang="scss">
  .cover {
    .progressive-background-image {
      background-position: 65% 45%;
    }
  }
</style>
