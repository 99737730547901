import amplitude from 'amplitude-js';

const project = amplitude.getInstance();

project.init(process.env.VUE_APP_AMPLITUDE_KEY);

function track(name, data) {
  project.logEvent(name, data);
}

const tracking = {
  track,
};

export default {
  install(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$amplitude = tracking;
  },
  ...tracking,
};
