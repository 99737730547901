import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import * as VueGoogleMaps from 'vue2-google-maps';

import '@/integrations';
import Amplitude from '@/amplitude';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';

Sentry.init({
  dsn: 'https://2766c37616de481e863d2955e9d0d819@o424699.ingest.sentry.io/5356826',
  environment: process.env.NODE_ENV,
  integrations: [new VueIntegration({ Vue, logErrors: true })],
});

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAPS_KEY,
  },
});

Vue.use(Amplitude);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
