<template>
  <div>
    <cover-header />
    <div id="main-container">
      <router-link
        to="/"
        class="header-link"
      >
        <page-header />
      </router-link>
      <nav-bar />
      <div class="section-with-margin">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import CoverHeader from '@/components/CoverHeader.vue';
import PageHeader from '@/components/PageHeader.vue';
import NavBar from '@/components/NavBar.vue';

export default {
  name: 'NavLayout',
  components: {
    CoverHeader,
    PageHeader,
    NavBar,
  },
};
</script>

<style lang="scss" scoped>
.header-link {
  color: inherit;
  text-decoration: none;
}

#main-container {
  min-height: 100vh;
}
</style>
