<template>
  <nav class="navbar">
    <ul class="navbar-list">
      <li
        v-for="(route, index) in main"
        :key="index"
        class="navbar-item"
      >
        <router-link
          :exact-active-class="route.exact ? 'active' : ''"
          :active-class="route.exact ? '' : 'active'"
          class="navbar-link"
          :to="route.to"
        >
          {{ route.name }}
        </router-link>
      </li>
      <li
        v-if="hadMenu"
        class="navbar-item"
      >
        <a
          class="navbar-link"
          @click="toggle"
        >{{ mainLength > 0 ? 'More' : 'Menu' }}</a>
        <div
          id="moreNavPopover"
          class="popover"
          :class="{ open: menuOpen }"
        >
          <ul class="popover-list">
            <li
              v-for="(route, index) in menu"
              :key="index"
              class="popover-item"
            >
              <router-link
                :exact-active-class="route.exact ? 'active' : ''"
                :active-class="route.exact ? '' : 'active'"
                class="popover-link"
                :to="route.to"
              >
                {{ route.name }}
              </router-link>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
const routes = [
  {
    to: '/',
    name: 'Wedding',
    exact: true,
  },
  {
    to: '/events',
    name: 'Events',
  },
  {
    to: '/wedding-party',
    name: 'Wedding Party',
  },
  {
    to: '/our-story',
    name: 'Our Story',
  },
  {
    to: '/travel-info',
    name: 'Travel Info',
  },
  {
    to: '/registry',
    name: 'Registry',
  },
  {
    to: '/faq',
    name: 'FAQ',
  },
];

export default {
  name: 'NavBar',
  data: () => ({
    menuOpen: false,
    width: null,
    routes,
  }),
  computed: {
    mainLength() {
      return Math.floor((this.width) / 160);
    },
    main() {
      if (this.routes.length === this.mainLength + 1) {
        return this.routes;
      }
      return this.routes.slice(0, this.mainLength);
    },
    menu() {
      return this.routes.slice(this.main.length);
    },
    hadMenu() {
      return this.menu.length > 0;
    },
  },
  mounted() {
    document.addEventListener('click', this.close);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    document.removeEventListener('click', this.close);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    close() {
      if (this.menuOpen) {
        this.menuOpen = false;
      }
    },
    toggle(e) {
      e.preventDefault();
      this.menuOpen = !this.menuOpen;
      e.stopImmediatePropagation();
    },
    handleResize() {
      this.width = window.innerWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100vw;
  margin-left: calc((100vw - 100%) / -2);
  min-height: 6.5rem;
  background: var(--background-color);
  z-index: 99;
  border-top: 1px solid var(--background-accent-color);
  border-bottom: 1px solid var(--background-accent-color);
  margin-bottom: 2rem;
}

.navbar-list {
  list-style: none;
  margin-bottom: 0;
}

.navbar-item {
  position: relative;
  float: left;
  margin-bottom: 0;
}

.navbar-link {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.2rem;
  margin: 0 16px;
  text-decoration: none;
  line-height: 6.5rem;
  color: var(--primary-color);
  box-sizing: border-box;
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.navbar-link.active {
  font-weight: 700;
}

/* Popover */
.popover.open {
  display: block;
}

.popover {
  display: none;
  position: absolute;
  background: var(--background-color);
  border: 1px solid var(--background-accent-color);
  border-radius: 4px;
  top: 92%;
  left: -20%;
  -webkit-filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.1));
  -moz-filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.1));
}

.popover-item {
  .popover-link {
    position: relative;
    color: var(--primary-color);
    display: block;
    padding: 8px 20px;
    border-bottom: 1px solid var(--background-accent-color);
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0.1rem;

    &:hover {
      color: var(--background-color);
      background: var(--primary-color);
      border-bottom-color: var(--primary-color);
    }
  }

  &:last-child {
    .popover-link {
      border-radius: 0 0 4px 4px;
      border-bottom-width: 0;
    }
  }

  &:first-child {
    .popover-link {
      &::after {
        bottom: 100%;
        left: 50%;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border: 10px solid rgba(255, 255, 255, 0);
        border-bottom-color: var(--background-color);
        margin-left: -10px;
      }

      &::before {
        bottom: 100%;
        left: 50%;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border: 11px solid rgba(238, 238, 238, 0);
        border-bottom-color: var(--background-accent-color);
        margin-left: -11px;
      }

      border-radius: 4px 4px 0 0;

      &:hover {
        &::after {
          border-bottom-color: var(--primary-color);
        }
      }
    }
  }

  padding: 0;
  margin: 0;
}

.popover-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.popover-link.active {
  font-weight: 700;
}
</style>
