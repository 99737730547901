<template>
  <div class="wedding">
    <section class="wedding-details">
      <div class="text">
        <p class="header">
          The Wedding
        </p>
        <p class="subheader">
          Saturday, August 13, 2022
          <br>
          Ceremony and Reception
        </p>
        <p>
          Husky Union Building
          <br>
          4001 E Stevens Way NE, Seattle, WA 98195
        </p>
      </div>
      <div class="image">
        <progressive-img
          placeholder="/images-placeholder/homepage/alex-and-lindsey-stairs.jpg"
          src="/images/homepage/alex-and-lindsey-stairs.jpg"
          :aspect-ratio="1.5"
        />
      </div>
    </section>
    <hr>
    <section class="footer">
      <router-link
        class="link"
        to="/travel-info"
      >
        Hotel
      </router-link>
      <router-link
        class="link"
        to="/events"
      >
        Event Timeline
      </router-link>
      <router-link
        class="link"
        to="/registry"
      >
        Registry
      </router-link>
      <router-link
        class="link"
        to="/faq"
      >
        FAQ
      </router-link>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
};
</script>

<style lang="scss" scoped>
.wedding {
  .wedding-details {
    text-align: center;
    align-items: center;

    @media (min-width: 550px) {
      display: flex;
    }

    .text,
    .image {
      flex: 1;
    }

    .image {
      padding: 0 40px;
    }
  }

  p {
    margin-bottom: 1rem;
    font-family: 'Raleway', sans-serif;

    &.header {
      font-family: 'Cinzel', serif;
      font-size: 3rem;
      line-height: 3rem;
      margin-bottom: 1.25rem;
    }

    &.subheader {
      opacity: 0.75;
      font-family: 'Cinzel', serif;
      font-size: 2rem;
      line-height: 2rem;
    }
  }

  .footer {
    text-align: center;

    :nth-child(n+2)::before {
      content: "|";
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}
</style>
