import Vue from 'vue';

import ChevronUpIcon from 'vue-material-design-icons/ChevronUp.vue';
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue';
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue';

import VueProgressiveImage from 'vue-progressive-image';

import Flickity from 'vue-flickity';

import iframeResize from 'iframe-resizer/js/iframeResizer';

require('./utilities/swipe');

Vue.component('ChevronUpIcon', ChevronUpIcon);
Vue.component('ChevronDownIcon', ChevronDownIcon);
Vue.component('ChevronLeftIcon', ChevronLeftIcon);
Vue.component('ChevronRightIcon', ChevronRightIcon);

Vue.use(VueProgressiveImage);

Vue.component('Flickity', Flickity);

Vue.directive('resize', {
  bind(el, { value = {} }) {
    el.addEventListener('load', () => iframeResize(value, el));
  },
  unbind(el) {
    el.iFrameResizer.removeListeners();
  },
});

Vue.config.productionTip = false;
