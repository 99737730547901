import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/index.vue';

import {
  HOME,
  EVENTS,
  WEDDING_PARTY,
  OUR_STORY,
  TRAVEL_INFO,
  REGISTRY,
  FAQ,
  REHEARSAL_FAQ,
} from './routes';

Vue.use(VueRouter);

export const routes = [
  {
    path: '/',
    name: HOME,
    component: Home,
    meta: {
      layout: 'nav',
    },
  },
  {
    path: '/events',
    name: EVENTS,
    meta: {
      layout: 'nav',
    },
    component: () => import(/* webpackChunkName: "events" */ '../views/events.vue'),
  },
  {
    path: '/wedding-party',
    name: WEDDING_PARTY,
    meta: {
      layout: 'nav',
    },
    component: () => import(/* webpackChunkName: "wedding-party" */ '../views/wedding-party.vue'),
  },
  {
    path: '/our-story',
    name: OUR_STORY,
    meta: {
      layout: 'nav',
    },
    component: () => import(/* webpackChunkName: "our-story" */ '../views/our-story.vue'),
  },
  {
    path: '/travel-info',
    name: TRAVEL_INFO,
    meta: {
      layout: 'nav',
    },
    component: () => import(/* webpackChunkName: "travel-info" */ '../views/travel-info.vue'),
  },
  {
    path: '/registry',
    name: REGISTRY,
    meta: {
      layout: 'nav',
    },
    component: () => import(/* webpackChunkName: "registry" */ '../views/registry.vue'),
  },
  {
    path: '/faq',
    name: FAQ,
    meta: {
      layout: 'nav',
    },
    component: () => import(/* webpackChunkName: "forgot" */ '../views/faq.vue'),
  },
  {
    path: '/rehearsal-faq',
    name: REHEARSAL_FAQ,
    meta: {
      layout: 'nav',
    },
    component: () => import(/* webpackChunkName: "forgot" */ '../views/rehearsal-faq.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    }

    if (savedPosition) {
      return savedPosition;
    }

    return { selector: '#main-container' };
  },
});

export default router;
